<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12 mb-3 text-center font19" >
      Donor Details
    </div>
    <div class="col-12 mb-3 text-center" >
      You're making this donation as:
    </div>
    <UserDisplay />
    <div class="col-10 my-4 text-center divider">
    </div>
    <ChangeDonor :donorTypes="donorTypes" v-model="selectedDonorType" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ChangeDonor: defineAsyncComponent(() => import('./views/ChangeDonor.vue')),
    UserDisplay: defineAsyncComponent(() => import('./views/UserDisplay.vue'))
  },
  name: 'User Details',
  data () {
    return {
      selectedDonorType: 'individual',
      donorTypes: ['individual', 'organisation']
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
